
.profile-header-image {
width: 66px;
height: 66px;
font-size: 14px;
border-radius: 50%;
overflow: hidden;
}

.profile-header-image  img {
width: 66px;
height: 66px;
}
.profile-header-text {
padding: 8px;
}

hr.profile-divider {
background: var(--amplify-colors-neutral-40);
height: 1px;
border: none;
margin: 20px 0 25px;
}

.profile-card-content .amplify-flex {
margin-bottom: 10px;
}

.profile-card-content .amplify-switchfield {
margin-bottom: 0;
display: block;
}

.profile-card-content {
position: relative;
min-height: 250px;
}


.profile-card-edit {
position: absolute;
bottom: 0px; 
right: 0px;
}

.profile-card-ev {
    position: relative;
    min-height: 250px;    
}